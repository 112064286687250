export type AnswerStatus = "WIN" | "LOSE" | "WAITING";

export const API_URL = 'https://api-prod.bop.rest';

export interface EuroQuestionsSet {
  id: number;
  name: string;
  points: number;
  status: "WAITING" | "ACTIVE" | "COMPLETED" | "MISSED" | "PREDICTED";
  startDate: string;
  endDate: string;
  answers: AnswerStatus[];
}

export interface User {
  id: number;
  photo: string;
  profileName: string;
}

export interface UserProfile {
  user: User;
  euroQuestionsSets: EuroQuestionsSet[];
  leaderboard?: LeaderboardQuiz;
}

export interface LeaderboardQuiz {
  position: number;
  points: number;
}

export interface LeaderboardEntry {
  position: number;
  points: number;
  user: User;
}

export interface Me {
  position: number;
  points: number;
  user: User;
}

export interface LeaderboardData {
  leaderboard: LeaderboardEntry[];
  me: Me;
}

export interface AnswerVariant {
  id: number;
  euro_answer_id: number;
  value: number;
  points: number;
  created_at: string;
  updated_at: string;
  isCorrect: boolean;
}

export interface Answer {
  id: number;
  euro_question_id: number;
  points: number;
  sort: number;
  created_at: string;
  updated_at: string;
  answer: string;
  isCorrect: boolean;
  img: string | null;
  buttonType: string;
  variants: AnswerVariant[];
}

export interface Question {
  id: number;
  euro_question_group_id: number;
  type: string;
  sort: number;
  created_at: string;
  updated_at: string;
  euro_users_answers: any[];
  question: string;
  isProcessed: boolean;
  answers: Answer[];
}

export interface Group {
  id: number;
  euro_question_set_id: number;
  sort: number;
  created_at: string;
  updated_at: string;
  name: string;
  questions: Question[];
}

export interface Stage {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  sort: number;
  groups: Group[];
  userAnswers: UserAnswer[];
}

export interface UserAnswer {
  questionId: number;
  pointsEarned: number;
  answers: {
    answerId: number;
    variantId: number
  }[];
}

export interface QuestionProps {
  question: Question,
  index: number,
  userAnswers: UserAnswer[]
  setStatus: string | undefined
}
